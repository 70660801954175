@tailwind components;
@tailwind utilities;

@layer components {
  .all-\[unset\] {
    all: unset;
  }
}

:root {
  --3-font-family: "Inter", Helvetica;
  --3-font-size: 14px;
  --3-font-style: normal;
  --3-font-weight: 400;
  --3-letter-spacing: 0px;
  --3-line-height: normal;
  --drop-shadow-0-12-0-8-0-5: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  --eng-body-medium-font-family: "Montserrat", Helvetica;
  --eng-body-medium-font-size: 16px;
  --eng-body-medium-font-style: normal;
  --eng-body-medium-font-weight: 500;
  --eng-body-medium-letter-spacing: 0px;
  --eng-body-medium-line-height: 24px;
  --eng-body-regular-font-family: "Montserrat", Helvetica;
  --eng-body-regular-font-size: 16px;
  --eng-body-regular-font-style: normal;
  --eng-body-regular-font-weight: 400;
  --eng-body-regular-letter-spacing: 0px;
  --eng-body-regular-line-height: 24px;
  --eng-body-semibold-font-family: "Montserrat", Helvetica;
  --eng-body-semibold-font-size: 16px;
  --eng-body-semibold-font-style: normal;
  --eng-body-semibold-font-weight: 600;
  --eng-body-semibold-letter-spacing: 0px;
  --eng-body-semibold-line-height: 24px;
  --eng-caption-medium-font-family: "Montserrat", Helvetica;
  --eng-caption-medium-font-size: 14px;
  --eng-caption-medium-font-style: normal;
  --eng-caption-medium-font-weight: 500;
  --eng-caption-medium-letter-spacing: 0px;
  --eng-caption-medium-line-height: 20px;
  --eng-caption-regular-font-family: "Montserrat", Helvetica;
  --eng-caption-regular-font-size: 14px;
  --eng-caption-regular-font-style: normal;
  --eng-caption-regular-font-weight: 400;
  --eng-caption-regular-letter-spacing: 0px;
  --eng-caption-regular-line-height: 20px;
  --eng-caption-semibold-font-family: "Montserrat", Helvetica;
  --eng-caption-semibold-font-size: 14px;
  --eng-caption-semibold-font-style: normal;
  --eng-caption-semibold-font-weight: 600;
  --eng-caption-semibold-letter-spacing: 0px;
  --eng-caption-semibold-line-height: 20px;
  --eng-h1-medium-font-family: "Montserrat", Helvetica;
  --eng-h1-medium-font-size: 60px;
  --eng-h1-medium-font-style: normal;
  --eng-h1-medium-font-weight: 500;
  --eng-h1-medium-letter-spacing: 0px;
  --eng-h1-medium-line-height: normal;
  --eng-h1-regular-font-family: "Montserrat", Helvetica;
  --eng-h1-regular-font-size: 60px;
  --eng-h1-regular-font-style: normal;
  --eng-h1-regular-font-weight: 400;
  --eng-h1-regular-letter-spacing: 0px;
  --eng-h1-regular-line-height: normal;
  --eng-h1-semibold-font-family: "Montserrat", Helvetica;
  --eng-h1-semibold-font-size: 60px;
  --eng-h1-semibold-font-style: normal;
  --eng-h1-semibold-font-weight: 600;
  --eng-h1-semibold-letter-spacing: 0px;
  --eng-h1-semibold-line-height: normal;
  --eng-h2-medium-font-family: "Montserrat", Helvetica;
  --eng-h2-medium-font-size: 48px;
  --eng-h2-medium-font-style: normal;
  --eng-h2-medium-font-weight: 500;
  --eng-h2-medium-letter-spacing: 0px;
  --eng-h2-medium-line-height: 60px;
  --eng-h2-regular-font-family: "Montserrat", Helvetica;
  --eng-h2-regular-font-size: 48px;
  --eng-h2-regular-font-style: normal;
  --eng-h2-regular-font-weight: 400;
  --eng-h2-regular-letter-spacing: 0px;
  --eng-h2-regular-line-height: 60px;
  --eng-h2-semibold-font-family: "Montserrat", Helvetica;
  --eng-h2-semibold-font-size: 48px;
  --eng-h2-semibold-font-style: normal;
  --eng-h2-semibold-font-weight: 600;
  --eng-h2-semibold-letter-spacing: 0px;
  --eng-h2-semibold-line-height: 60px;
  --eng-h3-medium-font-family: "Montserrat", Helvetica;
  --eng-h3-medium-font-size: 36px;
  --eng-h3-medium-font-style: normal;
  --eng-h3-medium-font-weight: 400;
  --eng-h3-medium-letter-spacing: 0px;
  --eng-h3-medium-line-height: 44px;
  --eng-h3-regular-font-family: "Montserrat", Helvetica;
  --eng-h3-regular-font-size: 36px;
  --eng-h3-regular-font-style: normal;
  --eng-h3-regular-font-weight: 400;
  --eng-h3-regular-letter-spacing: 0px;
  --eng-h3-regular-line-height: 44px;
  --eng-h3-semibold-font-family: "Montserrat", Helvetica;
  --eng-h3-semibold-font-size: 36px;
  --eng-h3-semibold-font-style: normal;
  --eng-h3-semibold-font-weight: 600;
  --eng-h3-semibold-letter-spacing: 0px;
  --eng-h3-semibold-line-height: 44px;
  --eng-h4-medium-font-family: "Montserrat", Helvetica;
  --eng-h4-medium-font-size: 32px;
  --eng-h4-medium-font-style: normal;
  --eng-h4-medium-font-weight: 500;
  --eng-h4-medium-letter-spacing: 0px;
  --eng-h4-medium-line-height: 40px;
  --eng-h4-regular-font-family: "Montserrat", Helvetica;
  --eng-h4-regular-font-size: 32px;
  --eng-h4-regular-font-style: normal;
  --eng-h4-regular-font-weight: 400;
  --eng-h4-regular-letter-spacing: 0px;
  --eng-h4-regular-line-height: 40px;
  --eng-h4-semibold-font-family: "Montserrat", Helvetica;
  --eng-h4-semibold-font-size: 32px;
  --eng-h4-semibold-font-style: normal;
  --eng-h4-semibold-font-weight: 600;
  --eng-h4-semibold-letter-spacing: 0px;
  --eng-h4-semibold-line-height: 40px;
  --eng-h5-medium-font-family: "Montserrat", Helvetica;
  --eng-h5-medium-font-size: 28px;
  --eng-h5-medium-font-style: normal;
  --eng-h5-medium-font-weight: 500;
  --eng-h5-medium-letter-spacing: 0px;
  --eng-h5-medium-line-height: 32px;
  --eng-h5-regular-font-family: "Montserrat", Helvetica;
  --eng-h5-regular-font-size: 28px;
  --eng-h5-regular-font-style: normal;
  --eng-h5-regular-font-weight: 400;
  --eng-h5-regular-letter-spacing: 0px;
  --eng-h5-regular-line-height: 32px;
  --eng-h5-semibold-font-family: "Montserrat", Helvetica;
  --eng-h5-semibold-font-size: 28px;
  --eng-h5-semibold-font-style: normal;
  --eng-h5-semibold-font-weight: 600;
  --eng-h5-semibold-letter-spacing: 0px;
  --eng-h5-semibold-line-height: 32px;
  --eng-subtitle1-medium-font-family: "Montserrat", Helvetica;
  --eng-subtitle1-medium-font-size: 24px;
  --eng-subtitle1-medium-font-style: normal;
  --eng-subtitle1-medium-font-weight: 500;
  --eng-subtitle1-medium-letter-spacing: 0px;
  --eng-subtitle1-medium-line-height: 32px;
  --eng-subtitle1-regular-font-family: "Montserrat", Helvetica;
  --eng-subtitle1-regular-font-size: 24px;
  --eng-subtitle1-regular-font-style: normal;
  --eng-subtitle1-regular-font-weight: 400;
  --eng-subtitle1-regular-letter-spacing: 0px;
  --eng-subtitle1-regular-line-height: 32px;
  --eng-subtitle1-semibold-font-family: "Montserrat", Helvetica;
  --eng-subtitle1-semibold-font-size: 24px;
  --eng-subtitle1-semibold-font-style: normal;
  --eng-subtitle1-semibold-font-weight: 600;
  --eng-subtitle1-semibold-letter-spacing: 0px;
  --eng-subtitle1-semibold-line-height: 32px;
  --eng-subtitle2-medium-font-family: "Montserrat", Helvetica;
  --eng-subtitle2-medium-font-size: 20px;
  --eng-subtitle2-medium-font-style: normal;
  --eng-subtitle2-medium-font-weight: 500;
  --eng-subtitle2-medium-letter-spacing: 0px;
  --eng-subtitle2-medium-line-height: 28px;
  --eng-subtitle2-regular-font-family: "Montserrat", Helvetica;
  --eng-subtitle2-regular-font-size: 20px;
  --eng-subtitle2-regular-font-style: normal;
  --eng-subtitle2-regular-font-weight: 400;
  --eng-subtitle2-regular-letter-spacing: 0px;
  --eng-subtitle2-regular-line-height: 28px;
  --eng-subtitle2-semibold-font-family: "Montserrat", Helvetica;
  --eng-subtitle2-semibold-font-size: 20px;
  --eng-subtitle2-semibold-font-style: normal;
  --eng-subtitle2-semibold-font-weight: 600;
  --eng-subtitle2-semibold-letter-spacing: 0px;
  --eng-subtitle2-semibold-line-height: 28px;
  --gmailgrey: rgba(93, 92, 93, 1);
  --grey-2: rgba(228, 228, 228, 1);
  --grey-3: rgba(83, 77, 89, 1);
  --grey-4: rgba(27, 33, 40, 1);
  --grey-5: rgba(149, 149, 149, 1);
  --h1-font-family: "Inter", Helvetica;
  --h1-font-size: 14px;
  --h1-font-style: normal;
  --h1-font-weight: 600;
  --h1-letter-spacing: 0px;
  --h1-line-height: normal;
  --h4-font-family: "Inter", Helvetica;
  --h4-font-size: 12px;
  --h4-font-style: normal;
  --h4-font-weight: 400;
  --h4-letter-spacing: 0px;
  --h4-line-height: normal;
  --main: rgba(120, 57, 205, 1);
  --noto-sans-14px-regular-font-family: "Noto Sans Thai", Helvetica;
  --noto-sans-14px-regular-font-size: 14px;
  --noto-sans-14px-regular-font-style: normal;
  --noto-sans-14px-regular-font-weight: 400;
  --noto-sans-14px-regular-letter-spacing: 0px;
  --noto-sans-14px-regular-line-height: 22px;
  --statusbggrey: rgba(228, 228, 228, 1);
  --statustextgrey: rgba(63, 55, 72, 1);
  --thai-body-medium-font-family: "Noto Sans Thai", Helvetica;
  --thai-body-medium-font-size: 16px;
  --thai-body-medium-font-style: normal;
  --thai-body-medium-font-weight: 500;
  --thai-body-medium-letter-spacing: 0px;
  --thai-body-medium-line-height: 24px;
  --thai-body-regular-font-family: "Noto Sans Thai", Helvetica;
  --thai-body-regular-font-size: 16px;
  --thai-body-regular-font-style: normal;
  --thai-body-regular-font-weight: 400;
  --thai-body-regular-letter-spacing: 0px;
  --thai-body-regular-line-height: 24px;
  --thai-body-semibold-font-family: "Noto Sans Thai", Helvetica;
  --thai-body-semibold-font-size: 16px;
  --thai-body-semibold-font-style: normal;
  --thai-body-semibold-font-weight: 600;
  --thai-body-semibold-letter-spacing: 0px;
  --thai-body-semibold-line-height: 24px;
  --thai-caption-medium-font-family: "Noto Sans Thai", Helvetica;
  --thai-caption-medium-font-size: 14px;
  --thai-caption-medium-font-style: normal;
  --thai-caption-medium-font-weight: 500;
  --thai-caption-medium-letter-spacing: 0px;
  --thai-caption-medium-line-height: 20px;
  --thai-caption-regular-font-family: "Noto Sans Thai", Helvetica;
  --thai-caption-regular-font-size: 14px;
  --thai-caption-regular-font-style: normal;
  --thai-caption-regular-font-weight: 400;
  --thai-caption-regular-letter-spacing: 0px;
  --thai-caption-regular-line-height: 20px;
  --thai-caption-semibold-font-family: "Noto Sans Thai", Helvetica;
  --thai-caption-semibold-font-size: 14px;
  --thai-caption-semibold-font-style: normal;
  --thai-caption-semibold-font-weight: 600;
  --thai-caption-semibold-letter-spacing: 0px;
  --thai-caption-semibold-line-height: 20px;
  --thai-h1-medium-font-family: "Noto Sans Thai", Helvetica;
  --thai-h1-medium-font-size: 60px;
  --thai-h1-medium-font-style: normal;
  --thai-h1-medium-font-weight: 500;
  --thai-h1-medium-letter-spacing: 0px;
  --thai-h1-medium-line-height: 92px;
  --thai-h1-regular-font-family: "Noto Sans Thai", Helvetica;
  --thai-h1-regular-font-size: 60px;
  --thai-h1-regular-font-style: normal;
  --thai-h1-regular-font-weight: 400;
  --thai-h1-regular-letter-spacing: 0px;
  --thai-h1-regular-line-height: 92px;
  --thai-h1-semibold-font-family: "Noto Sans Thai", Helvetica;
  --thai-h1-semibold-font-size: 60px;
  --thai-h1-semibold-font-style: normal;
  --thai-h1-semibold-font-weight: 600;
  --thai-h1-semibold-letter-spacing: 0px;
  --thai-h1-semibold-line-height: 92px;
  --thai-h2-medium-font-family: "Noto Sans Thai", Helvetica;
  --thai-h2-medium-font-size: 48px;
  --thai-h2-medium-font-style: normal;
  --thai-h2-medium-font-weight: 500;
  --thai-h2-medium-letter-spacing: 0px;
  --thai-h2-medium-line-height: 72px;
  --thai-h2-regular-font-family: "Noto Sans Thai", Helvetica;
  --thai-h2-regular-font-size: 48px;
  --thai-h2-regular-font-style: normal;
  --thai-h2-regular-font-weight: 400;
  --thai-h2-regular-letter-spacing: 0px;
  --thai-h2-regular-line-height: 72px;
  --thai-h2-semibold-font-family: "Noto Sans Thai", Helvetica;
  --thai-h2-semibold-font-size: 48px;
  --thai-h2-semibold-font-style: normal;
  --thai-h2-semibold-font-weight: 600;
  --thai-h2-semibold-letter-spacing: 0px;
  --thai-h2-semibold-line-height: 72px;
  --thai-h3-medium-font-family: "Noto Sans Thai", Helvetica;
  --thai-h3-medium-font-size: 36px;
  --thai-h3-medium-font-style: normal;
  --thai-h3-medium-font-weight: 500;
  --thai-h3-medium-letter-spacing: 0px;
  --thai-h3-medium-line-height: 48px;
  --thai-h3-regular-font-family: "Noto Sans Thai", Helvetica;
  --thai-h3-regular-font-size: 36px;
  --thai-h3-regular-font-style: normal;
  --thai-h3-regular-font-weight: 400;
  --thai-h3-regular-letter-spacing: 0px;
  --thai-h3-regular-line-height: 48px;
  --thai-h3-semibold-font-family: "Noto Sans Thai", Helvetica;
  --thai-h3-semibold-font-size: 36px;
  --thai-h3-semibold-font-style: normal;
  --thai-h3-semibold-font-weight: 600;
  --thai-h3-semibold-letter-spacing: 0px;
  --thai-h3-semibold-line-height: 48px;
  --thai-h4-medium-font-family: "Noto Sans Thai", Helvetica;
  --thai-h4-medium-font-size: 32px;
  --thai-h4-medium-font-style: normal;
  --thai-h4-medium-font-weight: 500;
  --thai-h4-medium-letter-spacing: 0px;
  --thai-h4-medium-line-height: 48px;
  --thai-h4-regular-font-family: "Noto Sans Thai", Helvetica;
  --thai-h4-regular-font-size: 32px;
  --thai-h4-regular-font-style: normal;
  --thai-h4-regular-font-weight: 400;
  --thai-h4-regular-letter-spacing: 0px;
  --thai-h4-regular-line-height: 48px;
  --thai-h4-semibold-font-family: "Noto Sans Thai", Helvetica;
  --thai-h4-semibold-font-size: 32px;
  --thai-h4-semibold-font-style: normal;
  --thai-h4-semibold-font-weight: 600;
  --thai-h4-semibold-letter-spacing: 0px;
  --thai-h4-semibold-line-height: 48px;
  --thai-h5-medium-font-family: "Noto Sans Thai", Helvetica;
  --thai-h5-medium-font-size: 28px;
  --thai-h5-medium-font-style: normal;
  --thai-h5-medium-font-weight: 500;
  --thai-h5-medium-letter-spacing: 0px;
  --thai-h5-medium-line-height: 42px;
  --thai-h5-regular-font-family: "Noto Sans Thai", Helvetica;
  --thai-h5-regular-font-size: 28px;
  --thai-h5-regular-font-style: normal;
  --thai-h5-regular-font-weight: 400;
  --thai-h5-regular-letter-spacing: 0px;
  --thai-h5-regular-line-height: 42px;
  --thai-h5-semibold-font-family: "Noto Sans Thai", Helvetica;
  --thai-h5-semibold-font-size: 28px;
  --thai-h5-semibold-font-style: normal;
  --thai-h5-semibold-font-weight: 600;
  --thai-h5-semibold-letter-spacing: 0px;
  --thai-h5-semibold-line-height: 42px;
  --thai-subtitle1-medium-font-family: "Noto Sans Thai", Helvetica;
  --thai-subtitle1-medium-font-size: 24px;
  --thai-subtitle1-medium-font-style: normal;
  --thai-subtitle1-medium-font-weight: 500;
  --thai-subtitle1-medium-letter-spacing: 0px;
  --thai-subtitle1-medium-line-height: 36px;
  --thai-subtitle1-regular-font-family: "Noto Sans Thai", Helvetica;
  --thai-subtitle1-regular-font-size: 24px;
  --thai-subtitle1-regular-font-style: normal;
  --thai-subtitle1-regular-font-weight: 400;
  --thai-subtitle1-regular-letter-spacing: 0px;
  --thai-subtitle1-regular-line-height: 36px;
  --thai-subtitle1-semibold-font-family: "Noto Sans Thai", Helvetica;
  --thai-subtitle1-semibold-font-size: 24px;
  --thai-subtitle1-semibold-font-style: normal;
  --thai-subtitle1-semibold-font-weight: 600;
  --thai-subtitle1-semibold-letter-spacing: 0px;
  --thai-subtitle1-semibold-line-height: 36px;
  --thai-subtitle2-medium-font-family: "Noto Sans Thai", Helvetica;
  --thai-subtitle2-medium-font-size: 20px;
  --thai-subtitle2-medium-font-style: normal;
  --thai-subtitle2-medium-font-weight: 500;
  --thai-subtitle2-medium-letter-spacing: 0px;
  --thai-subtitle2-medium-line-height: 32px;
  --thai-subtitle2-regular-font-family: "Noto Sans Thai", Helvetica;
  --thai-subtitle2-regular-font-size: 20px;
  --thai-subtitle2-regular-font-style: normal;
  --thai-subtitle2-regular-font-weight: 400;
  --thai-subtitle2-regular-letter-spacing: 0px;
  --thai-subtitle2-regular-line-height: 32px;
  --thai-subtitle2-semibold-font-family: "Noto Sans Thai", Helvetica;
  --thai-subtitle2-semibold-font-size: 20px;
  --thai-subtitle2-semibold-font-style: normal;
  --thai-subtitle2-semibold-font-weight: 600;
  --thai-subtitle2-semibold-letter-spacing: 0px;
  --thai-subtitle2-semibold-line-height: 32px;
  --white: rgba(255, 255, 255, 1);
}
