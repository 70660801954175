.all-\[unset\] {
  all: unset;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.left-0 {
  left: 0;
}

.left-1\.5 {
  left: .375rem;
}

.left-14 {
  left: 3.5rem;
}

.left-16 {
  left: 4rem;
}

.left-2 {
  left: .5rem;
}

.left-2\.5 {
  left: .625rem;
}

.left-24 {
  left: 6rem;
}

.left-28 {
  left: 7rem;
}

.left-3 {
  left: .75rem;
}

.left-\[-7px\] {
  left: -7px;
}

.left-\[104px\] {
  left: 104px;
}

.left-\[107px\] {
  left: 107px;
}

.left-\[118px\] {
  left: 118px;
}

.left-\[132px\] {
  left: 132px;
}

.left-\[146px\] {
  left: 146px;
}

.left-\[148px\] {
  left: 148px;
}

.left-\[150px\] {
  left: 150px;
}

.left-\[152px\] {
  left: 152px;
}

.left-\[15px\] {
  left: 15px;
}

.left-\[170px\] {
  left: 170px;
}

.left-\[171px\] {
  left: 171px;
}

.left-\[173px\] {
  left: 173px;
}

.left-\[18px\] {
  left: 18px;
}

.left-\[218px\] {
  left: 218px;
}

.left-\[22px\] {
  left: 22px;
}

.left-\[365px\] {
  left: 365px;
}

.left-\[380px\] {
  left: 380px;
}

.left-\[398px\] {
  left: 398px;
}

.left-\[399px\] {
  left: 399px;
}

.left-\[39px\] {
  left: 39px;
}

.left-\[3px\] {
  left: 3px;
}

.left-\[413px\] {
  left: 413px;
}

.left-\[46px\] {
  left: 46px;
}

.left-\[50px\] {
  left: 50px;
}

.left-\[548px\] {
  left: 548px;
}

.left-\[576px\] {
  left: 576px;
}

.left-\[587px\] {
  left: 587px;
}

.left-\[5px\] {
  left: 5px;
}

.left-\[60px\] {
  left: 60px;
}

.left-\[71px\] {
  left: 71px;
}

.left-\[72px\] {
  left: 72px;
}

.left-\[76px\] {
  left: 76px;
}

.left-\[779px\] {
  left: 779px;
}

.left-\[780px\] {
  left: 780px;
}

.left-\[79px\] {
  left: 79px;
}

.left-\[81px\] {
  left: 81px;
}

.left-\[83px\] {
  left: 83px;
}

.left-\[84px\] {
  left: 84px;
}

.left-\[87px\] {
  left: 87px;
}

.left-\[88px\] {
  left: 88px;
}

.left-\[98px\] {
  left: 98px;
}

.top-0 {
  top: 0;
}

.top-1\.5 {
  top: .375rem;
}

.top-10 {
  top: 2.5rem;
}

.top-11 {
  top: 2.75rem;
}

.top-12 {
  top: 3rem;
}

.top-16 {
  top: 4rem;
}

.top-2 {
  top: .5rem;
}

.top-28 {
  top: 7rem;
}

.top-3 {
  top: .75rem;
}

.top-3\.5 {
  top: .875rem;
}

.top-32 {
  top: 8rem;
}

.top-5 {
  top: 1.25rem;
}

.top-6 {
  top: 1.5rem;
}

.top-7 {
  top: 1.75rem;
}

.top-8 {
  top: 2rem;
}

.top-\[-5px\] {
  top: -5px;
}

.top-\[100px\] {
  top: 100px;
}

.top-\[103px\] {
  top: 103px;
}

.top-\[105px\] {
  top: 105px;
}

.top-\[108px\] {
  top: 108px;
}

.top-\[1102px\] {
  top: 1102px;
}

.top-\[116px\] {
  top: 116px;
}

.top-\[117px\] {
  top: 117px;
}

.top-\[119px\] {
  top: 119px;
}

.top-\[11px\] {
  top: 11px;
}

.top-\[1250px\] {
  top: 1250px;
}

.top-\[127px\] {
  top: 127px;
}

.top-\[134px\] {
  top: 134px;
}

.top-\[1367px\] {
  top: 1367px;
}

.top-\[13px\] {
  top: 13px;
}

.top-\[141px\] {
  top: 141px;
}

.top-\[149px\] {
  top: 149px;
}

.top-\[152px\] {
  top: 152px;
}

.top-\[187px\] {
  top: 187px;
}

.top-\[19px\] {
  top: 19px;
}

.top-\[2007px\] {
  top: 2007px;
}

.top-\[2096px\] {
  top: 2096px;
}

.top-\[22px\] {
  top: 22px;
}

.top-\[23px\] {
  top: 23px;
}

.top-\[25px\] {
  top: 25px;
}

.top-\[30px\] {
  top: 30px;
}

.top-\[34px\] {
  top: 34px;
}

.top-\[3571px\] {
  top: 3571px;
}

.top-\[35px\] {
  top: 35px;
}

.top-\[3641px\] {
  top: 3641px;
}

.top-\[37px\] {
  top: 37px;
}

.top-\[39px\] {
  top: 39px;
}

.top-\[3px\] {
  top: 3px;
}

.top-\[42px\] {
  top: 42px;
}

.top-\[4309px\] {
  top: 4309px;
}

.top-\[45px\] {
  top: 45px;
}

.top-\[46px\] {
  top: 46px;
}

.top-\[5085px\] {
  top: 5085px;
}

.top-\[50px\] {
  top: 50px;
}

.top-\[5220px\] {
  top: 5220px;
}

.top-\[53px\] {
  top: 53px;
}

.top-\[543px\] {
  top: 543px;
}

.top-\[544px\] {
  top: 544px;
}

.top-\[5578px\] {
  top: 5578px;
}

.top-\[55px\] {
  top: 55px;
}

.top-\[57px\] {
  top: 57px;
}

.top-\[59px\] {
  top: 59px;
}

.top-\[60px\] {
  top: 60px;
}

.top-\[62px\] {
  top: 62px;
}

.top-\[65px\] {
  top: 65px;
}

.top-\[66px\] {
  top: 66px;
}

.top-\[67px\] {
  top: 67px;
}

.top-\[70px\] {
  top: 70px;
}

.top-\[72px\] {
  top: 72px;
}

.top-\[7525px\] {
  top: 7525px;
}

.top-\[78px\] {
  top: 78px;
}

.top-\[79px\] {
  top: 79px;
}

.top-\[7px\] {
  top: 7px;
}

.top-\[87px\] {
  top: 87px;
}

.top-\[88px\] {
  top: 88px;
}

.top-\[90px\] {
  top: 90px;
}

.top-\[94px\] {
  top: 94px;
}

.top-\[963px\] {
  top: 963px;
}

.top-\[97px\] {
  top: 97px;
}

.top-px {
  top: 1px;
}

.mb-\[-0\.29px\] {
  margin-bottom: -.29px;
}

.ml-\[-0\.29px\] {
  margin-left: -.29px;
}

.ml-\[-1\.00px\] {
  margin-left: -1px;
}

.mr-\[-0\.29px\] {
  margin-right: -.29px;
}

.mr-\[-1\.00px\] {
  margin-right: -1px;
}

.mr-\[-10\.00px\] {
  margin-right: -10px;
}

.mr-\[-2\.00px\] {
  margin-right: -2px;
}

.mr-\[-3\.50px\] {
  margin-right: -3.5px;
}

.mr-\[-35\.00px\] {
  margin-right: -35px;
}

.mr-\[-8\.50px\] {
  margin-right: -8.5px;
}

.mt-\[-0\.29px\] {
  margin-top: -.29px;
}

.mt-\[-1\.00px\] {
  margin-top: -1px;
}

.mt-\[-3\.00px\] {
  margin-top: -3px;
}

.box-border {
  box-sizing: border-box;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.h-1 {
  height: .25rem;
}

.h-1\.5 {
  height: .375rem;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2\.5 {
  height: .625rem;
}

.h-24 {
  height: 6rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-60 {
  height: 15rem;
}

.h-8 {
  height: 2rem;
}

.h-\[11px\] {
  height: 11px;
}

.h-\[126\.53px\] {
  height: 126.53px;
}

.h-\[1272px\] {
  height: 1272px;
}

.h-\[133\.6px\] {
  height: 133.6px;
}

.h-\[134px\] {
  height: 134px;
}

.h-\[142\.74px\] {
  height: 142.74px;
}

.h-\[1475px\] {
  height: 1475px;
}

.h-\[163px\] {
  height: 163px;
}

.h-\[164\.87px\] {
  height: 164.87px;
}

.h-\[171\.67px\] {
  height: 171.67px;
}

.h-\[19px\] {
  height: 19px;
}

.h-\[205px\] {
  height: 205px;
}

.h-\[210\.73px\] {
  height: 210.73px;
}

.h-\[2294px\] {
  height: 2294px;
}

.h-\[233px\] {
  height: 233px;
}

.h-\[235px\] {
  height: 235px;
}

.h-\[239px\] {
  height: 239px;
}

.h-\[244\.14px\] {
  height: 244.14px;
}

.h-\[25px\] {
  height: 25px;
}

.h-\[26px\] {
  height: 26px;
}

.h-\[296\.8px\] {
  height: 296.8px;
}

.h-\[296px\] {
  height: 296px;
}

.h-\[31px\] {
  height: 31px;
}

.h-\[325px\] {
  height: 325px;
}

.h-\[332px\] {
  height: 332px;
}

.h-\[3544px\] {
  height: 3544px;
}

.h-\[3px\] {
  height: 3px;
}

.h-\[46px\] {
  height: 46px;
}

.h-\[54px\] {
  height: 54px;
}

.h-\[55\.81px\] {
  height: 55.81px;
}

.h-\[5984px\] {
  height: 5984px;
}

.h-\[5px\] {
  height: 5px;
}

.h-\[66px\] {
  height: 66px;
}

.h-\[67px\] {
  height: 67px;
}

.h-\[68\.22px\] {
  height: 68.22px;
}

.h-\[72px\] {
  height: 72px;
}

.h-\[74px\] {
  height: 74px;
}

.h-\[781px\] {
  height: 781px;
}

.h-\[84px\] {
  height: 84px;
}

.h-\[8685px\] {
  height: 8685px;
}

.h-\[92px\] {
  height: 92px;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-20 {
  width: 5rem;
}

.w-4 {
  width: 1rem;
}

.w-40 {
  width: 10rem;
}

.w-5 {
  width: 1.25rem;
}

.w-7 {
  width: 1.75rem;
}

.w-9 {
  width: 2.25rem;
}

.w-\[102px\] {
  width: 102px;
}

.w-\[103px\] {
  width: 103px;
}

.w-\[106px\] {
  width: 106px;
}

.w-\[117px\] {
  width: 117px;
}

.w-\[118px\] {
  width: 118px;
}

.w-\[119px\] {
  width: 119px;
}

.w-\[120px\] {
  width: 120px;
}

.w-\[1216px\] {
  width: 1216px;
}

.w-\[121px\] {
  width: 121px;
}

.w-\[125px\] {
  width: 125px;
}

.w-\[128\.01px\] {
  width: 128.01px;
}

.w-\[131px\] {
  width: 131px;
}

.w-\[1440px\] {
  width: 1440px;
}

.w-\[160\.01px\] {
  width: 160.01px;
}

.w-\[172\.9px\] {
  width: 172.9px;
}

.w-\[178\.34px\] {
  width: 178.34px;
}

.w-\[179px\] {
  width: 179px;
}

.w-\[180px\] {
  width: 180px;
}

.w-\[183px\] {
  width: 183px;
}

.w-\[184px\] {
  width: 184px;
}

.w-\[190px\] {
  width: 190px;
}

.w-\[191px\] {
  width: 191px;
}

.w-\[202px\] {
  width: 202px;
}

.w-\[212px\] {
  width: 212px;
}

.w-\[214\.09px\] {
  width: 214.09px;
}

.w-\[215px\] {
  width: 215px;
}

.w-\[224\.01px\] {
  width: 224.01px;
}

.w-\[246px\] {
  width: 246px;
}

.w-\[248px\] {
  width: 248px;
}

.w-\[255px\] {
  width: 255px;
}

.w-\[260px\] {
  width: 260px;
}

.w-\[26px\] {
  width: 26px;
}

.w-\[274\.85px\] {
  width: 274.85px;
}

.w-\[276px\] {
  width: 276px;
}

.w-\[280px\] {
  width: 280px;
}

.w-\[284\.41px\] {
  width: 284.41px;
}

.w-\[290\.03px\] {
  width: 290.03px;
}

.w-\[302px\] {
  width: 302px;
}

.w-\[307\.63px\] {
  width: 307.63px;
}

.w-\[308px\] {
  width: 308px;
}

.w-\[314px\] {
  width: 314px;
}

.w-\[31px\] {
  width: 31px;
}

.w-\[33px\] {
  width: 33px;
}

.w-\[349\.25px\] {
  width: 349.25px;
}

.w-\[350px\] {
  width: 350px;
}

.w-\[351\.66px\] {
  width: 351.66px;
}

.w-\[352px\] {
  width: 352px;
}

.w-\[35px\] {
  width: 35px;
}

.w-\[38px\] {
  width: 38px;
}

.w-\[393px\] {
  width: 393px;
}

.w-\[42px\] {
  width: 42px;
}

.w-\[43px\] {
  width: 43px;
}

.w-\[483px\] {
  width: 483px;
}

.w-\[505px\] {
  width: 505px;
}

.w-\[50px\] {
  width: 50px;
}

.w-\[518px\] {
  width: 518px;
}

.w-\[51px\] {
  width: 51px;
}

.w-\[55px\] {
  width: 55px;
}

.w-\[59px\] {
  width: 59px;
}

.w-\[62px\] {
  width: 62px;
}

.w-\[65px\] {
  width: 65px;
}

.w-\[66px\] {
  width: 66px;
}

.w-\[70px\] {
  width: 70px;
}

.w-\[72px\] {
  width: 72px;
}

.w-\[73px\] {
  width: 73px;
}

.w-\[74px\] {
  width: 74px;
}

.w-\[77px\] {
  width: 77px;
}

.w-\[82px\] {
  width: 82px;
}

.w-\[83px\] {
  width: 83px;
}

.w-\[86px\] {
  width: 86px;
}

.w-\[898px\] {
  width: 898px;
}

.w-\[92px\] {
  width: 92px;
}

.w-\[95px\] {
  width: 95px;
}

.w-\[99px\] {
  width: 99px;
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.flex-1 {
  flex: 1;
}

.flex-\[0_0_auto\] {
  flex: none;
}

.grow {
  flex-grow: 1;
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.gap-1 {
  gap: .25rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-14 {
  gap: 3.5rem;
}

.gap-16 {
  gap: 4rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-2\.5 {
  gap: .625rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-9 {
  gap: 2.25rem;
}

.gap-\[118px\] {
  gap: 118px;
}

.gap-\[12\.6px\] {
  gap: 12.6px;
}

.gap-\[2\.94px\] {
  gap: 2.94px;
}

.gap-\[231px\] {
  gap: 231px;
}

.gap-\[251px\] {
  gap: 251px;
}

.gap-\[3\.53px\] {
  gap: 3.53px;
}

.gap-\[33px\] {
  gap: 33px;
}

.gap-\[57px\] {
  gap: 57px;
}

.gap-\[6\.54px\] {
  gap: 6.54px;
}

.gap-\[6\.85px\] {
  gap: 6.85px;
}

.gap-\[72px\] {
  gap: 72px;
}

.gap-\[88px\] {
  gap: 88px;
}

.self-stretch {
  align-self: stretch;
}

.overflow-hidden {
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-\[0\.49px\] {
  border-radius: .49px;
}

.rounded-\[0\.88px\] {
  border-radius: .88px;
}

.rounded-\[1\.15px\] {
  border-radius: 1.15px;
}

.rounded-\[100px\] {
  border-radius: 100px;
}

.rounded-\[30px\] {
  border-radius: 30px;
}

.border {
  border-width: 1px;
}

.border-\[0\.12px\] {
  border-width: .12px;
}

.border-\[0\.22px\] {
  border-width: .22px;
}

.border-\[0\.29px\] {
  border-width: .29px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.border-r-\[0\.29px\] {
  border-right-width: .29px;
}

.border-t {
  border-top-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-none {
  border-style: none;
}

.border-\[\#434343\] {
  --tw-border-opacity: 1;
  border-color: rgb(67 67 67 / var(--tw-border-opacity));
}

.border-\[\#667fbf\] {
  --tw-border-opacity: 1;
  border-color: rgb(102 127 191 / var(--tw-border-opacity));
}

.border-\[\#8c8c8c\] {
  --tw-border-opacity: 1;
  border-color: rgb(140 140 140 / var(--tw-border-opacity));
}

.border-\[\#bfbfbf\] {
  --tw-border-opacity: 1;
  border-color: rgb(191 191 191 / var(--tw-border-opacity));
}

.border-\[\#d9d9d9\] {
  --tw-border-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-border-opacity));
}

.bg-\[\#bae7ff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(186 231 255 / var(--tw-bg-opacity));
}

.bg-\[\#c81f1f\] {
  --tw-bg-opacity: 1;
  background-color: rgb(200 31 31 / var(--tw-bg-opacity));
}

.bg-\[\#d9d9d9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(217 217 217 / var(--tw-bg-opacity));
}

.bg-\[\#d9f7be\] {
  --tw-bg-opacity: 1;
  background-color: rgb(217 247 190 / var(--tw-bg-opacity));
}

.bg-\[\#f0f0f0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(240 240 240 / var(--tw-bg-opacity));
}

.bg-\[\#f7f9ff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(247 249 255 / var(--tw-bg-opacity));
}

.bg-\[\#fcc536\] {
  --tw-bg-opacity: 1;
  background-color: rgb(252 197 54 / var(--tw-bg-opacity));
}

.bg-\[\#fef1d6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(254 241 214 / var(--tw-bg-opacity));
}

.bg-\[\#fff1b8\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 241 184 / var(--tw-bg-opacity));
}

.bg-\[\#fff2d7\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 242 215 / var(--tw-bg-opacity));
}

.bg-\[\#ffffff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-neutral-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}

.bg-white {
  background-color: var(--white);
}

.object-cover {
  object-fit: cover;
}

.p-10 {
  padding: 2.5rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-8 {
  padding: 2rem;
}

.p-\[60px\] {
  padding: 60px;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-28 {
  padding-left: 7rem;
  padding-right: 7rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-\[5\.88px\] {
  padding-left: 5.88px;
  padding-right: 5.88px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.py-\[60px\] {
  padding-top: 60px;
  padding-bottom: 60px;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-14 {
  padding-bottom: 3.5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-7 {
  padding-bottom: 1.75rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pb-\[18px\] {
  padding-bottom: 18px;
}

.pb-\[6\.17px\] {
  padding-bottom: 6.17px;
}

.pl-0 {
  padding-left: 0;
}

.pl-12 {
  padding-left: 3rem;
}

.pl-14 {
  padding-left: 3.5rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pl-\[104px\] {
  padding-left: 104px;
}

.pl-\[39px\] {
  padding-left: 39px;
}

.pl-\[57px\] {
  padding-left: 57px;
}

.pl-\[60px\] {
  padding-left: 60px;
}

.pl-\[92px\] {
  padding-left: 92px;
}

.pr-0 {
  padding-right: 0;
}

.pr-12 {
  padding-right: 3rem;
}

.pr-9 {
  padding-right: 2.25rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-\[5\.88px\] {
  padding-top: 5.88px;
}

.pt-\[60px\] {
  padding-top: 60px;
}

.text-center {
  text-align: center;
}

.font-eng-body-medium {
  font-family: var(--eng-body-medium-font-family);
}

.font-eng-body-regular {
  font-family: var(--eng-body-regular-font-family);
}

.font-eng-caption-regular {
  font-family: var(--eng-caption-regular-font-family);
}

.font-eng-subtitle2-regular {
  font-family: var(--eng-subtitle2-regular-font-family);
}

.font-eng-subtitle2-semibold {
  font-family: var(--eng-subtitle2-semibold-font-family);
}

.font-thai-body-medium {
  font-family: var(--thai-body-medium-font-family);
}

.font-thai-body-regular {
  font-family: var(--thai-body-regular-font-family);
}

.font-thai-caption-regular {
  font-family: var(--thai-caption-regular-font-family);
}

.font-thai-h1-semibold {
  font-family: var(--thai-h1-semibold-font-family);
}

.font-thai-h2-medium {
  font-family: var(--thai-h2-medium-font-family);
}

.font-thai-h2-regular {
  font-family: var(--thai-h2-regular-font-family);
}

.font-thai-h2-semibold {
  font-family: var(--thai-h2-semibold-font-family);
}

.font-thai-h3-medium {
  font-family: var(--thai-h3-medium-font-family);
}

.font-thai-h3-regular {
  font-family: var(--thai-h3-regular-font-family);
}

.font-thai-h3-semibold {
  font-family: var(--thai-h3-semibold-font-family);
}

.font-thai-h4-regular {
  font-family: var(--thai-h4-regular-font-family);
}

.font-thai-h4-semibold {
  font-family: var(--thai-h4-semibold-font-family);
}

.font-thai-h5-medium {
  font-family: var(--thai-h5-medium-font-family);
}

.font-thai-h5-regular {
  font-family: var(--thai-h5-regular-font-family);
}

.font-thai-subtitle1-medium {
  font-family: var(--thai-subtitle1-medium-font-family);
}

.font-thai-subtitle1-regular {
  font-family: var(--thai-subtitle1-regular-font-family);
}

.font-thai-subtitle1-semibold {
  font-family: var(--thai-subtitle1-semibold-font-family);
}

.font-thai-subtitle2-medium {
  font-family: var(--thai-subtitle2-medium-font-family);
}

.font-thai-subtitle2-regular {
  font-family: var(--thai-subtitle2-regular-font-family);
}

.font-thai-subtitle2-semibold {
  font-family: var(--thai-subtitle2-semibold-font-family);
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-\[15\.4px\] {
  font-size: 15.4px;
}

.text-\[28px\] {
  font-size: 28px;
}

.text-\[32px\] {
  font-size: 32px;
}

.text-\[4\.6px\] {
  font-size: 4.6px;
}

.text-\[8\.2px\] {
  font-size: 8.2px;
}

.text-\[length\:var\(--eng-body-medium-font-size\)\] {
  font-size: var(--eng-body-medium-font-size);
}

.text-\[length\:var\(--eng-body-regular-font-size\)\] {
  font-size: var(--eng-body-regular-font-size);
}

.text-\[length\:var\(--eng-caption-regular-font-size\)\] {
  font-size: var(--eng-caption-regular-font-size);
}

.text-\[length\:var\(--eng-subtitle2-regular-font-size\)\] {
  font-size: var(--eng-subtitle2-regular-font-size);
}

.text-\[length\:var\(--eng-subtitle2-semibold-font-size\)\] {
  font-size: var(--eng-subtitle2-semibold-font-size);
}

.text-\[length\:var\(--thai-body-medium-font-size\)\] {
  font-size: var(--thai-body-medium-font-size);
}

.text-\[length\:var\(--thai-body-regular-font-size\)\] {
  font-size: var(--thai-body-regular-font-size);
}

.text-\[length\:var\(--thai-caption-regular-font-size\)\] {
  font-size: var(--thai-caption-regular-font-size);
}

.text-\[length\:var\(--thai-h1-semibold-font-size\)\] {
  font-size: var(--thai-h1-semibold-font-size);
}

.text-\[length\:var\(--thai-h2-medium-font-size\)\] {
  font-size: var(--thai-h2-medium-font-size);
}

.text-\[length\:var\(--thai-h2-regular-font-size\)\] {
  font-size: var(--thai-h2-regular-font-size);
}

.text-\[length\:var\(--thai-h2-semibold-font-size\)\] {
  font-size: var(--thai-h2-semibold-font-size);
}

.text-\[length\:var\(--thai-h3-medium-font-size\)\] {
  font-size: var(--thai-h3-medium-font-size);
}

.text-\[length\:var\(--thai-h3-regular-font-size\)\] {
  font-size: var(--thai-h3-regular-font-size);
}

.text-\[length\:var\(--thai-h3-semibold-font-size\)\] {
  font-size: var(--thai-h3-semibold-font-size);
}

.text-\[length\:var\(--thai-h4-regular-font-size\)\] {
  font-size: var(--thai-h4-regular-font-size);
}

.text-\[length\:var\(--thai-h4-semibold-font-size\)\] {
  font-size: var(--thai-h4-semibold-font-size);
}

.text-\[length\:var\(--thai-h5-medium-font-size\)\] {
  font-size: var(--thai-h5-medium-font-size);
}

.text-\[length\:var\(--thai-h5-regular-font-size\)\] {
  font-size: var(--thai-h5-regular-font-size);
}

.text-\[length\:var\(--thai-subtitle1-medium-font-size\)\] {
  font-size: var(--thai-subtitle1-medium-font-size);
}

.text-\[length\:var\(--thai-subtitle1-regular-font-size\)\] {
  font-size: var(--thai-subtitle1-regular-font-size);
}

.text-\[length\:var\(--thai-subtitle1-semibold-font-size\)\] {
  font-size: var(--thai-subtitle1-semibold-font-size);
}

.text-\[length\:var\(--thai-subtitle2-medium-font-size\)\] {
  font-size: var(--thai-subtitle2-medium-font-size);
}

.text-\[length\:var\(--thai-subtitle2-regular-font-size\)\] {
  font-size: var(--thai-subtitle2-regular-font-size);
}

.text-\[length\:var\(--thai-subtitle2-semibold-font-size\)\] {
  font-size: var(--thai-subtitle2-semibold-font-size);
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-\[number\:var\(--eng-body-medium-font-weight\)\] {
  font-weight: var(--eng-body-medium-font-weight);
}

.font-\[number\:var\(--eng-body-regular-font-weight\)\] {
  font-weight: var(--eng-body-regular-font-weight);
}

.font-\[number\:var\(--eng-caption-regular-font-weight\)\] {
  font-weight: var(--eng-caption-regular-font-weight);
}

.font-\[number\:var\(--eng-subtitle2-regular-font-weight\)\] {
  font-weight: var(--eng-subtitle2-regular-font-weight);
}

.font-\[number\:var\(--eng-subtitle2-semibold-font-weight\)\] {
  font-weight: var(--eng-subtitle2-semibold-font-weight);
}

.font-\[number\:var\(--thai-body-medium-font-weight\)\] {
  font-weight: var(--thai-body-medium-font-weight);
}

.font-\[number\:var\(--thai-body-regular-font-weight\)\] {
  font-weight: var(--thai-body-regular-font-weight);
}

.font-\[number\:var\(--thai-caption-regular-font-weight\)\] {
  font-weight: var(--thai-caption-regular-font-weight);
}

.font-\[number\:var\(--thai-h1-semibold-font-weight\)\] {
  font-weight: var(--thai-h1-semibold-font-weight);
}

.font-\[number\:var\(--thai-h2-medium-font-weight\)\] {
  font-weight: var(--thai-h2-medium-font-weight);
}

.font-\[number\:var\(--thai-h2-regular-font-weight\)\] {
  font-weight: var(--thai-h2-regular-font-weight);
}

.font-\[number\:var\(--thai-h2-semibold-font-weight\)\] {
  font-weight: var(--thai-h2-semibold-font-weight);
}

.font-\[number\:var\(--thai-h3-medium-font-weight\)\] {
  font-weight: var(--thai-h3-medium-font-weight);
}

.font-\[number\:var\(--thai-h3-regular-font-weight\)\] {
  font-weight: var(--thai-h3-regular-font-weight);
}

.font-\[number\:var\(--thai-h3-semibold-font-weight\)\] {
  font-weight: var(--thai-h3-semibold-font-weight);
}

.font-\[number\:var\(--thai-h4-regular-font-weight\)\] {
  font-weight: var(--thai-h4-regular-font-weight);
}

.font-\[number\:var\(--thai-h4-semibold-font-weight\)\] {
  font-weight: var(--thai-h4-semibold-font-weight);
}

.font-\[number\:var\(--thai-h5-medium-font-weight\)\] {
  font-weight: var(--thai-h5-medium-font-weight);
}

.font-\[number\:var\(--thai-h5-regular-font-weight\)\] {
  font-weight: var(--thai-h5-regular-font-weight);
}

.font-\[number\:var\(--thai-subtitle1-medium-font-weight\)\] {
  font-weight: var(--thai-subtitle1-medium-font-weight);
}

.font-\[number\:var\(--thai-subtitle1-regular-font-weight\)\] {
  font-weight: var(--thai-subtitle1-regular-font-weight);
}

.font-\[number\:var\(--thai-subtitle1-semibold-font-weight\)\] {
  font-weight: var(--thai-subtitle1-semibold-font-weight);
}

.font-\[number\:var\(--thai-subtitle2-medium-font-weight\)\] {
  font-weight: var(--thai-subtitle2-medium-font-weight);
}

.font-\[number\:var\(--thai-subtitle2-regular-font-weight\)\] {
  font-weight: var(--thai-subtitle2-regular-font-weight);
}

.font-\[number\:var\(--thai-subtitle2-semibold-font-weight\)\] {
  font-weight: var(--thai-subtitle2-semibold-font-weight);
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-\[48px\] {
  line-height: 48px;
}

.leading-\[60px\] {
  line-height: 60px;
}

.leading-\[72px\] {
  line-height: 72px;
}

.leading-\[normal\] {
  line-height: normal;
}

.leading-\[var\(--eng-body-medium-line-height\)\] {
  line-height: var(--eng-body-medium-line-height);
}

.leading-\[var\(--eng-body-regular-line-height\)\] {
  line-height: var(--eng-body-regular-line-height);
}

.leading-\[var\(--eng-caption-regular-line-height\)\] {
  line-height: var(--eng-caption-regular-line-height);
}

.leading-\[var\(--eng-subtitle2-regular-line-height\)\] {
  line-height: var(--eng-subtitle2-regular-line-height);
}

.leading-\[var\(--eng-subtitle2-semibold-line-height\)\] {
  line-height: var(--eng-subtitle2-semibold-line-height);
}

.leading-\[var\(--thai-body-medium-line-height\)\] {
  line-height: var(--thai-body-medium-line-height);
}

.leading-\[var\(--thai-body-regular-line-height\)\] {
  line-height: var(--thai-body-regular-line-height);
}

.leading-\[var\(--thai-caption-regular-line-height\)\] {
  line-height: var(--thai-caption-regular-line-height);
}

.leading-\[var\(--thai-h1-semibold-line-height\)\] {
  line-height: var(--thai-h1-semibold-line-height);
}

.leading-\[var\(--thai-h2-medium-line-height\)\] {
  line-height: var(--thai-h2-medium-line-height);
}

.leading-\[var\(--thai-h2-regular-line-height\)\] {
  line-height: var(--thai-h2-regular-line-height);
}

.leading-\[var\(--thai-h2-semibold-line-height\)\] {
  line-height: var(--thai-h2-semibold-line-height);
}

.leading-\[var\(--thai-h3-medium-line-height\)\] {
  line-height: var(--thai-h3-medium-line-height);
}

.leading-\[var\(--thai-h3-regular-line-height\)\] {
  line-height: var(--thai-h3-regular-line-height);
}

.leading-\[var\(--thai-h3-semibold-line-height\)\] {
  line-height: var(--thai-h3-semibold-line-height);
}

.leading-\[var\(--thai-h4-regular-line-height\)\] {
  line-height: var(--thai-h4-regular-line-height);
}

.leading-\[var\(--thai-h4-semibold-line-height\)\] {
  line-height: var(--thai-h4-semibold-line-height);
}

.leading-\[var\(--thai-h5-medium-line-height\)\] {
  line-height: var(--thai-h5-medium-line-height);
}

.leading-\[var\(--thai-h5-regular-line-height\)\] {
  line-height: var(--thai-h5-regular-line-height);
}

.leading-\[var\(--thai-subtitle1-medium-line-height\)\] {
  line-height: var(--thai-subtitle1-medium-line-height);
}

.leading-\[var\(--thai-subtitle1-regular-line-height\)\] {
  line-height: var(--thai-subtitle1-regular-line-height);
}

.leading-\[var\(--thai-subtitle1-semibold-line-height\)\] {
  line-height: var(--thai-subtitle1-semibold-line-height);
}

.leading-\[var\(--thai-subtitle2-medium-line-height\)\] {
  line-height: var(--thai-subtitle2-medium-line-height);
}

.leading-\[var\(--thai-subtitle2-regular-line-height\)\] {
  line-height: var(--thai-subtitle2-regular-line-height);
}

.leading-\[var\(--thai-subtitle2-semibold-line-height\)\] {
  line-height: var(--thai-subtitle2-semibold-line-height);
}

.tracking-\[0\] {
  letter-spacing: 0;
}

.tracking-\[var\(--eng-body-medium-letter-spacing\)\] {
  letter-spacing: var(--eng-body-medium-letter-spacing);
}

.tracking-\[var\(--eng-body-regular-letter-spacing\)\] {
  letter-spacing: var(--eng-body-regular-letter-spacing);
}

.tracking-\[var\(--eng-caption-regular-letter-spacing\)\] {
  letter-spacing: var(--eng-caption-regular-letter-spacing);
}

.tracking-\[var\(--eng-subtitle2-regular-letter-spacing\)\] {
  letter-spacing: var(--eng-subtitle2-regular-letter-spacing);
}

.tracking-\[var\(--eng-subtitle2-semibold-letter-spacing\)\] {
  letter-spacing: var(--eng-subtitle2-semibold-letter-spacing);
}

.tracking-\[var\(--thai-body-medium-letter-spacing\)\] {
  letter-spacing: var(--thai-body-medium-letter-spacing);
}

.tracking-\[var\(--thai-body-regular-letter-spacing\)\] {
  letter-spacing: var(--thai-body-regular-letter-spacing);
}

.tracking-\[var\(--thai-caption-regular-letter-spacing\)\] {
  letter-spacing: var(--thai-caption-regular-letter-spacing);
}

.tracking-\[var\(--thai-h1-semibold-letter-spacing\)\] {
  letter-spacing: var(--thai-h1-semibold-letter-spacing);
}

.tracking-\[var\(--thai-h2-medium-letter-spacing\)\] {
  letter-spacing: var(--thai-h2-medium-letter-spacing);
}

.tracking-\[var\(--thai-h2-regular-letter-spacing\)\] {
  letter-spacing: var(--thai-h2-regular-letter-spacing);
}

.tracking-\[var\(--thai-h2-semibold-letter-spacing\)\] {
  letter-spacing: var(--thai-h2-semibold-letter-spacing);
}

.tracking-\[var\(--thai-h3-medium-letter-spacing\)\] {
  letter-spacing: var(--thai-h3-medium-letter-spacing);
}

.tracking-\[var\(--thai-h3-regular-letter-spacing\)\] {
  letter-spacing: var(--thai-h3-regular-letter-spacing);
}

.tracking-\[var\(--thai-h3-semibold-letter-spacing\)\] {
  letter-spacing: var(--thai-h3-semibold-letter-spacing);
}

.tracking-\[var\(--thai-h4-regular-letter-spacing\)\] {
  letter-spacing: var(--thai-h4-regular-letter-spacing);
}

.tracking-\[var\(--thai-h4-semibold-letter-spacing\)\] {
  letter-spacing: var(--thai-h4-semibold-letter-spacing);
}

.tracking-\[var\(--thai-h5-medium-letter-spacing\)\] {
  letter-spacing: var(--thai-h5-medium-letter-spacing);
}

.tracking-\[var\(--thai-h5-regular-letter-spacing\)\] {
  letter-spacing: var(--thai-h5-regular-letter-spacing);
}

.tracking-\[var\(--thai-subtitle1-medium-letter-spacing\)\] {
  letter-spacing: var(--thai-subtitle1-medium-letter-spacing);
}

.tracking-\[var\(--thai-subtitle1-regular-letter-spacing\)\] {
  letter-spacing: var(--thai-subtitle1-regular-letter-spacing);
}

.tracking-\[var\(--thai-subtitle1-semibold-letter-spacing\)\] {
  letter-spacing: var(--thai-subtitle1-semibold-letter-spacing);
}

.tracking-\[var\(--thai-subtitle2-medium-letter-spacing\)\] {
  letter-spacing: var(--thai-subtitle2-medium-letter-spacing);
}

.tracking-\[var\(--thai-subtitle2-regular-letter-spacing\)\] {
  letter-spacing: var(--thai-subtitle2-regular-letter-spacing);
}

.tracking-\[var\(--thai-subtitle2-semibold-letter-spacing\)\] {
  letter-spacing: var(--thai-subtitle2-semibold-letter-spacing);
}

.text-\[\#434343\] {
  --tw-text-opacity: 1;
  color: rgb(67 67 67 / var(--tw-text-opacity));
}

.text-\[\#595959\] {
  --tw-text-opacity: 1;
  color: rgb(89 89 89 / var(--tw-text-opacity));
}

.text-\[\#8c8c8c\] {
  --tw-text-opacity: 1;
  color: rgb(140 140 140 / var(--tw-text-opacity));
}

.text-\[\#bfbfbf\] {
  --tw-text-opacity: 1;
  color: rgb(191 191 191 / var(--tw-text-opacity));
}

.text-\[\#fcc536\] {
  --tw-text-opacity: 1;
  color: rgb(252 197 54 / var(--tw-text-opacity));
}

.text-\[\#ffffff\] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-grey-4 {
  color: var(--grey-4);
}

.text-neutral-800 {
  --tw-text-opacity: 1;
  color: rgb(38 38 38 / var(--tw-text-opacity));
}

.text-transparent {
  color: #0000;
}

.opacity-80 {
  opacity: .8;
}

.opacity-90 {
  opacity: .9;
}

.shadow-\[inset_0px_-0\.29px_0px_\#e1e1e1\] {
  --tw-shadow: inset 0px -.29px 0px #e1e1e1;
  --tw-shadow-colored: inset 0px -.29px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-drop-shadow-0-12-0-8-0-5 {
  --tw-shadow: var(--drop-shadow-0-12-0-8-0-5);
  --tw-shadow-colored: var(--drop-shadow-0-12-0-8-0-5);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.\[border-bottom-style\:solid\] {
  border-bottom-style: solid;
}

.\[border-left-style\:solid\] {
  border-left-style: solid;
}

.\[border-right-style\:solid\] {
  border-right-style: solid;
}

.\[border-top-style\:solid\] {
  border-top-style: solid;
}

.\[font-family\:\'Inter\'\,Helvetica\] {
  font-family: Inter, Helvetica;
}

.\[font-family\:\'Noto_Sans_Thai\'\,Helvetica\] {
  font-family: Noto Sans Thai, Helvetica;
}

.\[font-family\:\'Noto_sans_Thai-Regular\'\,Helvetica\] {
  font-family: Noto sans Thai-Regular, Helvetica;
}

.\[font-family\:\'Noto_sans_Thai-SemiBold\'\,Helvetica\] {
  font-family: Noto sans Thai-SemiBold, Helvetica;
}

.\[font-style\:var\(--eng-body-medium-font-style\)\] {
  font-style: var(--eng-body-medium-font-style);
}

.\[font-style\:var\(--eng-body-regular-font-style\)\] {
  font-style: var(--eng-body-regular-font-style);
}

.\[font-style\:var\(--eng-caption-regular-font-style\)\] {
  font-style: var(--eng-caption-regular-font-style);
}

.\[font-style\:var\(--eng-subtitle2-regular-font-style\)\] {
  font-style: var(--eng-subtitle2-regular-font-style);
}

.\[font-style\:var\(--eng-subtitle2-semibold-font-style\)\] {
  font-style: var(--eng-subtitle2-semibold-font-style);
}

.\[font-style\:var\(--thai-body-medium-font-style\)\] {
  font-style: var(--thai-body-medium-font-style);
}

.\[font-style\:var\(--thai-body-regular-font-style\)\] {
  font-style: var(--thai-body-regular-font-style);
}

.\[font-style\:var\(--thai-caption-regular-font-style\)\] {
  font-style: var(--thai-caption-regular-font-style);
}

.\[font-style\:var\(--thai-h1-semibold-font-style\)\] {
  font-style: var(--thai-h1-semibold-font-style);
}

.\[font-style\:var\(--thai-h2-medium-font-style\)\] {
  font-style: var(--thai-h2-medium-font-style);
}

.\[font-style\:var\(--thai-h2-regular-font-style\)\] {
  font-style: var(--thai-h2-regular-font-style);
}

.\[font-style\:var\(--thai-h2-semibold-font-style\)\] {
  font-style: var(--thai-h2-semibold-font-style);
}

.\[font-style\:var\(--thai-h3-medium-font-style\)\] {
  font-style: var(--thai-h3-medium-font-style);
}

.\[font-style\:var\(--thai-h3-regular-font-style\)\] {
  font-style: var(--thai-h3-regular-font-style);
}

.\[font-style\:var\(--thai-h3-semibold-font-style\)\] {
  font-style: var(--thai-h3-semibold-font-style);
}

.\[font-style\:var\(--thai-h4-regular-font-style\)\] {
  font-style: var(--thai-h4-regular-font-style);
}

.\[font-style\:var\(--thai-h4-semibold-font-style\)\] {
  font-style: var(--thai-h4-semibold-font-style);
}

.\[font-style\:var\(--thai-h5-medium-font-style\)\] {
  font-style: var(--thai-h5-medium-font-style);
}

.\[font-style\:var\(--thai-h5-regular-font-style\)\] {
  font-style: var(--thai-h5-regular-font-style);
}

.\[font-style\:var\(--thai-subtitle1-medium-font-style\)\] {
  font-style: var(--thai-subtitle1-medium-font-style);
}

.\[font-style\:var\(--thai-subtitle1-regular-font-style\)\] {
  font-style: var(--thai-subtitle1-regular-font-style);
}

.\[font-style\:var\(--thai-subtitle1-semibold-font-style\)\] {
  font-style: var(--thai-subtitle1-semibold-font-style);
}

.\[font-style\:var\(--thai-subtitle2-medium-font-style\)\] {
  font-style: var(--thai-subtitle2-medium-font-style);
}

.\[font-style\:var\(--thai-subtitle2-regular-font-style\)\] {
  font-style: var(--thai-subtitle2-regular-font-style);
}

.\[font-style\:var\(--thai-subtitle2-semibold-font-style\)\] {
  font-style: var(--thai-subtitle2-semibold-font-style);
}

:root {
  --3-font-family: "Inter", Helvetica;
  --3-font-size: 14px;
  --3-font-style: normal;
  --3-font-weight: 400;
  --3-letter-spacing: 0px;
  --3-line-height: normal;
  --drop-shadow-0-12-0-8-0-5: 0px 9px 28px 8px #0000000d, 0px 6px 16px 0px #00000014, 0px 3px 6px -4px #0000001f;
  --eng-body-medium-font-family: "Montserrat", Helvetica;
  --eng-body-medium-font-size: 16px;
  --eng-body-medium-font-style: normal;
  --eng-body-medium-font-weight: 500;
  --eng-body-medium-letter-spacing: 0px;
  --eng-body-medium-line-height: 24px;
  --eng-body-regular-font-family: "Montserrat", Helvetica;
  --eng-body-regular-font-size: 16px;
  --eng-body-regular-font-style: normal;
  --eng-body-regular-font-weight: 400;
  --eng-body-regular-letter-spacing: 0px;
  --eng-body-regular-line-height: 24px;
  --eng-body-semibold-font-family: "Montserrat", Helvetica;
  --eng-body-semibold-font-size: 16px;
  --eng-body-semibold-font-style: normal;
  --eng-body-semibold-font-weight: 600;
  --eng-body-semibold-letter-spacing: 0px;
  --eng-body-semibold-line-height: 24px;
  --eng-caption-medium-font-family: "Montserrat", Helvetica;
  --eng-caption-medium-font-size: 14px;
  --eng-caption-medium-font-style: normal;
  --eng-caption-medium-font-weight: 500;
  --eng-caption-medium-letter-spacing: 0px;
  --eng-caption-medium-line-height: 20px;
  --eng-caption-regular-font-family: "Montserrat", Helvetica;
  --eng-caption-regular-font-size: 14px;
  --eng-caption-regular-font-style: normal;
  --eng-caption-regular-font-weight: 400;
  --eng-caption-regular-letter-spacing: 0px;
  --eng-caption-regular-line-height: 20px;
  --eng-caption-semibold-font-family: "Montserrat", Helvetica;
  --eng-caption-semibold-font-size: 14px;
  --eng-caption-semibold-font-style: normal;
  --eng-caption-semibold-font-weight: 600;
  --eng-caption-semibold-letter-spacing: 0px;
  --eng-caption-semibold-line-height: 20px;
  --eng-h1-medium-font-family: "Montserrat", Helvetica;
  --eng-h1-medium-font-size: 60px;
  --eng-h1-medium-font-style: normal;
  --eng-h1-medium-font-weight: 500;
  --eng-h1-medium-letter-spacing: 0px;
  --eng-h1-medium-line-height: normal;
  --eng-h1-regular-font-family: "Montserrat", Helvetica;
  --eng-h1-regular-font-size: 60px;
  --eng-h1-regular-font-style: normal;
  --eng-h1-regular-font-weight: 400;
  --eng-h1-regular-letter-spacing: 0px;
  --eng-h1-regular-line-height: normal;
  --eng-h1-semibold-font-family: "Montserrat", Helvetica;
  --eng-h1-semibold-font-size: 60px;
  --eng-h1-semibold-font-style: normal;
  --eng-h1-semibold-font-weight: 600;
  --eng-h1-semibold-letter-spacing: 0px;
  --eng-h1-semibold-line-height: normal;
  --eng-h2-medium-font-family: "Montserrat", Helvetica;
  --eng-h2-medium-font-size: 48px;
  --eng-h2-medium-font-style: normal;
  --eng-h2-medium-font-weight: 500;
  --eng-h2-medium-letter-spacing: 0px;
  --eng-h2-medium-line-height: 60px;
  --eng-h2-regular-font-family: "Montserrat", Helvetica;
  --eng-h2-regular-font-size: 48px;
  --eng-h2-regular-font-style: normal;
  --eng-h2-regular-font-weight: 400;
  --eng-h2-regular-letter-spacing: 0px;
  --eng-h2-regular-line-height: 60px;
  --eng-h2-semibold-font-family: "Montserrat", Helvetica;
  --eng-h2-semibold-font-size: 48px;
  --eng-h2-semibold-font-style: normal;
  --eng-h2-semibold-font-weight: 600;
  --eng-h2-semibold-letter-spacing: 0px;
  --eng-h2-semibold-line-height: 60px;
  --eng-h3-medium-font-family: "Montserrat", Helvetica;
  --eng-h3-medium-font-size: 36px;
  --eng-h3-medium-font-style: normal;
  --eng-h3-medium-font-weight: 400;
  --eng-h3-medium-letter-spacing: 0px;
  --eng-h3-medium-line-height: 44px;
  --eng-h3-regular-font-family: "Montserrat", Helvetica;
  --eng-h3-regular-font-size: 36px;
  --eng-h3-regular-font-style: normal;
  --eng-h3-regular-font-weight: 400;
  --eng-h3-regular-letter-spacing: 0px;
  --eng-h3-regular-line-height: 44px;
  --eng-h3-semibold-font-family: "Montserrat", Helvetica;
  --eng-h3-semibold-font-size: 36px;
  --eng-h3-semibold-font-style: normal;
  --eng-h3-semibold-font-weight: 600;
  --eng-h3-semibold-letter-spacing: 0px;
  --eng-h3-semibold-line-height: 44px;
  --eng-h4-medium-font-family: "Montserrat", Helvetica;
  --eng-h4-medium-font-size: 32px;
  --eng-h4-medium-font-style: normal;
  --eng-h4-medium-font-weight: 500;
  --eng-h4-medium-letter-spacing: 0px;
  --eng-h4-medium-line-height: 40px;
  --eng-h4-regular-font-family: "Montserrat", Helvetica;
  --eng-h4-regular-font-size: 32px;
  --eng-h4-regular-font-style: normal;
  --eng-h4-regular-font-weight: 400;
  --eng-h4-regular-letter-spacing: 0px;
  --eng-h4-regular-line-height: 40px;
  --eng-h4-semibold-font-family: "Montserrat", Helvetica;
  --eng-h4-semibold-font-size: 32px;
  --eng-h4-semibold-font-style: normal;
  --eng-h4-semibold-font-weight: 600;
  --eng-h4-semibold-letter-spacing: 0px;
  --eng-h4-semibold-line-height: 40px;
  --eng-h5-medium-font-family: "Montserrat", Helvetica;
  --eng-h5-medium-font-size: 28px;
  --eng-h5-medium-font-style: normal;
  --eng-h5-medium-font-weight: 500;
  --eng-h5-medium-letter-spacing: 0px;
  --eng-h5-medium-line-height: 32px;
  --eng-h5-regular-font-family: "Montserrat", Helvetica;
  --eng-h5-regular-font-size: 28px;
  --eng-h5-regular-font-style: normal;
  --eng-h5-regular-font-weight: 400;
  --eng-h5-regular-letter-spacing: 0px;
  --eng-h5-regular-line-height: 32px;
  --eng-h5-semibold-font-family: "Montserrat", Helvetica;
  --eng-h5-semibold-font-size: 28px;
  --eng-h5-semibold-font-style: normal;
  --eng-h5-semibold-font-weight: 600;
  --eng-h5-semibold-letter-spacing: 0px;
  --eng-h5-semibold-line-height: 32px;
  --eng-subtitle1-medium-font-family: "Montserrat", Helvetica;
  --eng-subtitle1-medium-font-size: 24px;
  --eng-subtitle1-medium-font-style: normal;
  --eng-subtitle1-medium-font-weight: 500;
  --eng-subtitle1-medium-letter-spacing: 0px;
  --eng-subtitle1-medium-line-height: 32px;
  --eng-subtitle1-regular-font-family: "Montserrat", Helvetica;
  --eng-subtitle1-regular-font-size: 24px;
  --eng-subtitle1-regular-font-style: normal;
  --eng-subtitle1-regular-font-weight: 400;
  --eng-subtitle1-regular-letter-spacing: 0px;
  --eng-subtitle1-regular-line-height: 32px;
  --eng-subtitle1-semibold-font-family: "Montserrat", Helvetica;
  --eng-subtitle1-semibold-font-size: 24px;
  --eng-subtitle1-semibold-font-style: normal;
  --eng-subtitle1-semibold-font-weight: 600;
  --eng-subtitle1-semibold-letter-spacing: 0px;
  --eng-subtitle1-semibold-line-height: 32px;
  --eng-subtitle2-medium-font-family: "Montserrat", Helvetica;
  --eng-subtitle2-medium-font-size: 20px;
  --eng-subtitle2-medium-font-style: normal;
  --eng-subtitle2-medium-font-weight: 500;
  --eng-subtitle2-medium-letter-spacing: 0px;
  --eng-subtitle2-medium-line-height: 28px;
  --eng-subtitle2-regular-font-family: "Montserrat", Helvetica;
  --eng-subtitle2-regular-font-size: 20px;
  --eng-subtitle2-regular-font-style: normal;
  --eng-subtitle2-regular-font-weight: 400;
  --eng-subtitle2-regular-letter-spacing: 0px;
  --eng-subtitle2-regular-line-height: 28px;
  --eng-subtitle2-semibold-font-family: "Montserrat", Helvetica;
  --eng-subtitle2-semibold-font-size: 20px;
  --eng-subtitle2-semibold-font-style: normal;
  --eng-subtitle2-semibold-font-weight: 600;
  --eng-subtitle2-semibold-letter-spacing: 0px;
  --eng-subtitle2-semibold-line-height: 28px;
  --gmailgrey: #5d5c5d;
  --grey-2: #e4e4e4;
  --grey-3: #534d59;
  --grey-4: #1b2128;
  --grey-5: #959595;
  --h1-font-family: "Inter", Helvetica;
  --h1-font-size: 14px;
  --h1-font-style: normal;
  --h1-font-weight: 600;
  --h1-letter-spacing: 0px;
  --h1-line-height: normal;
  --h4-font-family: "Inter", Helvetica;
  --h4-font-size: 12px;
  --h4-font-style: normal;
  --h4-font-weight: 400;
  --h4-letter-spacing: 0px;
  --h4-line-height: normal;
  --main: #7839cd;
  --noto-sans-14px-regular-font-family: "Noto Sans Thai", Helvetica;
  --noto-sans-14px-regular-font-size: 14px;
  --noto-sans-14px-regular-font-style: normal;
  --noto-sans-14px-regular-font-weight: 400;
  --noto-sans-14px-regular-letter-spacing: 0px;
  --noto-sans-14px-regular-line-height: 22px;
  --statusbggrey: #e4e4e4;
  --statustextgrey: #3f3748;
  --thai-body-medium-font-family: "Noto Sans Thai", Helvetica;
  --thai-body-medium-font-size: 16px;
  --thai-body-medium-font-style: normal;
  --thai-body-medium-font-weight: 500;
  --thai-body-medium-letter-spacing: 0px;
  --thai-body-medium-line-height: 24px;
  --thai-body-regular-font-family: "Noto Sans Thai", Helvetica;
  --thai-body-regular-font-size: 16px;
  --thai-body-regular-font-style: normal;
  --thai-body-regular-font-weight: 400;
  --thai-body-regular-letter-spacing: 0px;
  --thai-body-regular-line-height: 24px;
  --thai-body-semibold-font-family: "Noto Sans Thai", Helvetica;
  --thai-body-semibold-font-size: 16px;
  --thai-body-semibold-font-style: normal;
  --thai-body-semibold-font-weight: 600;
  --thai-body-semibold-letter-spacing: 0px;
  --thai-body-semibold-line-height: 24px;
  --thai-caption-medium-font-family: "Noto Sans Thai", Helvetica;
  --thai-caption-medium-font-size: 14px;
  --thai-caption-medium-font-style: normal;
  --thai-caption-medium-font-weight: 500;
  --thai-caption-medium-letter-spacing: 0px;
  --thai-caption-medium-line-height: 20px;
  --thai-caption-regular-font-family: "Noto Sans Thai", Helvetica;
  --thai-caption-regular-font-size: 14px;
  --thai-caption-regular-font-style: normal;
  --thai-caption-regular-font-weight: 400;
  --thai-caption-regular-letter-spacing: 0px;
  --thai-caption-regular-line-height: 20px;
  --thai-caption-semibold-font-family: "Noto Sans Thai", Helvetica;
  --thai-caption-semibold-font-size: 14px;
  --thai-caption-semibold-font-style: normal;
  --thai-caption-semibold-font-weight: 600;
  --thai-caption-semibold-letter-spacing: 0px;
  --thai-caption-semibold-line-height: 20px;
  --thai-h1-medium-font-family: "Noto Sans Thai", Helvetica;
  --thai-h1-medium-font-size: 60px;
  --thai-h1-medium-font-style: normal;
  --thai-h1-medium-font-weight: 500;
  --thai-h1-medium-letter-spacing: 0px;
  --thai-h1-medium-line-height: 92px;
  --thai-h1-regular-font-family: "Noto Sans Thai", Helvetica;
  --thai-h1-regular-font-size: 60px;
  --thai-h1-regular-font-style: normal;
  --thai-h1-regular-font-weight: 400;
  --thai-h1-regular-letter-spacing: 0px;
  --thai-h1-regular-line-height: 92px;
  --thai-h1-semibold-font-family: "Noto Sans Thai", Helvetica;
  --thai-h1-semibold-font-size: 60px;
  --thai-h1-semibold-font-style: normal;
  --thai-h1-semibold-font-weight: 600;
  --thai-h1-semibold-letter-spacing: 0px;
  --thai-h1-semibold-line-height: 92px;
  --thai-h2-medium-font-family: "Noto Sans Thai", Helvetica;
  --thai-h2-medium-font-size: 48px;
  --thai-h2-medium-font-style: normal;
  --thai-h2-medium-font-weight: 500;
  --thai-h2-medium-letter-spacing: 0px;
  --thai-h2-medium-line-height: 72px;
  --thai-h2-regular-font-family: "Noto Sans Thai", Helvetica;
  --thai-h2-regular-font-size: 48px;
  --thai-h2-regular-font-style: normal;
  --thai-h2-regular-font-weight: 400;
  --thai-h2-regular-letter-spacing: 0px;
  --thai-h2-regular-line-height: 72px;
  --thai-h2-semibold-font-family: "Noto Sans Thai", Helvetica;
  --thai-h2-semibold-font-size: 48px;
  --thai-h2-semibold-font-style: normal;
  --thai-h2-semibold-font-weight: 600;
  --thai-h2-semibold-letter-spacing: 0px;
  --thai-h2-semibold-line-height: 72px;
  --thai-h3-medium-font-family: "Noto Sans Thai", Helvetica;
  --thai-h3-medium-font-size: 36px;
  --thai-h3-medium-font-style: normal;
  --thai-h3-medium-font-weight: 500;
  --thai-h3-medium-letter-spacing: 0px;
  --thai-h3-medium-line-height: 48px;
  --thai-h3-regular-font-family: "Noto Sans Thai", Helvetica;
  --thai-h3-regular-font-size: 36px;
  --thai-h3-regular-font-style: normal;
  --thai-h3-regular-font-weight: 400;
  --thai-h3-regular-letter-spacing: 0px;
  --thai-h3-regular-line-height: 48px;
  --thai-h3-semibold-font-family: "Noto Sans Thai", Helvetica;
  --thai-h3-semibold-font-size: 36px;
  --thai-h3-semibold-font-style: normal;
  --thai-h3-semibold-font-weight: 600;
  --thai-h3-semibold-letter-spacing: 0px;
  --thai-h3-semibold-line-height: 48px;
  --thai-h4-medium-font-family: "Noto Sans Thai", Helvetica;
  --thai-h4-medium-font-size: 32px;
  --thai-h4-medium-font-style: normal;
  --thai-h4-medium-font-weight: 500;
  --thai-h4-medium-letter-spacing: 0px;
  --thai-h4-medium-line-height: 48px;
  --thai-h4-regular-font-family: "Noto Sans Thai", Helvetica;
  --thai-h4-regular-font-size: 32px;
  --thai-h4-regular-font-style: normal;
  --thai-h4-regular-font-weight: 400;
  --thai-h4-regular-letter-spacing: 0px;
  --thai-h4-regular-line-height: 48px;
  --thai-h4-semibold-font-family: "Noto Sans Thai", Helvetica;
  --thai-h4-semibold-font-size: 32px;
  --thai-h4-semibold-font-style: normal;
  --thai-h4-semibold-font-weight: 600;
  --thai-h4-semibold-letter-spacing: 0px;
  --thai-h4-semibold-line-height: 48px;
  --thai-h5-medium-font-family: "Noto Sans Thai", Helvetica;
  --thai-h5-medium-font-size: 28px;
  --thai-h5-medium-font-style: normal;
  --thai-h5-medium-font-weight: 500;
  --thai-h5-medium-letter-spacing: 0px;
  --thai-h5-medium-line-height: 42px;
  --thai-h5-regular-font-family: "Noto Sans Thai", Helvetica;
  --thai-h5-regular-font-size: 28px;
  --thai-h5-regular-font-style: normal;
  --thai-h5-regular-font-weight: 400;
  --thai-h5-regular-letter-spacing: 0px;
  --thai-h5-regular-line-height: 42px;
  --thai-h5-semibold-font-family: "Noto Sans Thai", Helvetica;
  --thai-h5-semibold-font-size: 28px;
  --thai-h5-semibold-font-style: normal;
  --thai-h5-semibold-font-weight: 600;
  --thai-h5-semibold-letter-spacing: 0px;
  --thai-h5-semibold-line-height: 42px;
  --thai-subtitle1-medium-font-family: "Noto Sans Thai", Helvetica;
  --thai-subtitle1-medium-font-size: 24px;
  --thai-subtitle1-medium-font-style: normal;
  --thai-subtitle1-medium-font-weight: 500;
  --thai-subtitle1-medium-letter-spacing: 0px;
  --thai-subtitle1-medium-line-height: 36px;
  --thai-subtitle1-regular-font-family: "Noto Sans Thai", Helvetica;
  --thai-subtitle1-regular-font-size: 24px;
  --thai-subtitle1-regular-font-style: normal;
  --thai-subtitle1-regular-font-weight: 400;
  --thai-subtitle1-regular-letter-spacing: 0px;
  --thai-subtitle1-regular-line-height: 36px;
  --thai-subtitle1-semibold-font-family: "Noto Sans Thai", Helvetica;
  --thai-subtitle1-semibold-font-size: 24px;
  --thai-subtitle1-semibold-font-style: normal;
  --thai-subtitle1-semibold-font-weight: 600;
  --thai-subtitle1-semibold-letter-spacing: 0px;
  --thai-subtitle1-semibold-line-height: 36px;
  --thai-subtitle2-medium-font-family: "Noto Sans Thai", Helvetica;
  --thai-subtitle2-medium-font-size: 20px;
  --thai-subtitle2-medium-font-style: normal;
  --thai-subtitle2-medium-font-weight: 500;
  --thai-subtitle2-medium-letter-spacing: 0px;
  --thai-subtitle2-medium-line-height: 32px;
  --thai-subtitle2-regular-font-family: "Noto Sans Thai", Helvetica;
  --thai-subtitle2-regular-font-size: 20px;
  --thai-subtitle2-regular-font-style: normal;
  --thai-subtitle2-regular-font-weight: 400;
  --thai-subtitle2-regular-letter-spacing: 0px;
  --thai-subtitle2-regular-line-height: 32px;
  --thai-subtitle2-semibold-font-family: "Noto Sans Thai", Helvetica;
  --thai-subtitle2-semibold-font-size: 20px;
  --thai-subtitle2-semibold-font-style: normal;
  --thai-subtitle2-semibold-font-weight: 600;
  --thai-subtitle2-semibold-letter-spacing: 0px;
  --thai-subtitle2-semibold-line-height: 32px;
  --white: #fff;
}
/*# sourceMappingURL=index.16daf924.css.map */
